import { render, staticRenderFns } from "./Dropdown.vue?vue&type=template&id=64d7d648&"
import script from "./Dropdown.vue?vue&type=script&lang=js&"
export * from "./Dropdown.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesButton: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Button.vue').default,AtomsIcon: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Icon.vue').default,AtomsOnClickOutside: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/OnClickOutside.vue').default})
