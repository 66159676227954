//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { debounce } from 'lodash-es'
import { getName } from 'country-list'
import CHECK_SERIAL_QUERY from '~/queries/warranty/checkSerial.gql'
import CHECK_COUPON_QUERY from '~/queries/warranty/checkCoupon.gql'
import buildValidationRules from '~/components/molecules/extend-warranty/formValidationRules'
import { getLocationInfo } from '~/lib/geocoder'
import { toHTML } from '~/lib/utils/markdown'

const equipmentFields = ['serialno', 'articleno', 'installed', 'name']
const CouponCodeMessage = {
  ERROR: 'extend_warranty.form.coupon_code_error',
  SUCCESS: 'extend_warranty.form.coupon_code_success'
}

export default {
  props: {
    thing: {
      type: Object,
      required: true
    },
    currentStep: {
      type: Number,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      couponCodeMessage: null,
      isCouponApplied: false,
      showSerialWarning: [false],
      formData: {
        // device_details
        equipment: [
          {
            serialno: this.thing.sn || '',
            articleno: this.thing.model || '',
            installed: new Date().toISOString().split('T')[0],
            name: this.thing.name || ''
          }
        ],
        // practice_details
        practice: '',
        street: '',
        zip: '',
        town: '',
        country: '',
        email: '',
        telephone: '',
        honorific: '',
        title: '',
        firstname: '',
        lastname: '',
        // dental_depot
        dealer_name: '',
        dealer_town: '',
        dealer_email: '',
        dealer_telephone: '',
        // confirmation
        tos: false,
        termsNewProduct: false,
        dataProcessing: false,
        warranty: false,
        installation: false,
        news: false,
        coupon_code: undefined
      }
    }
  },
  computed: {
    organization() {
      return this.thing.org
        ? this.$store.getters['organization/byId'](this.thing.org) // thing organization
        : this.$store.getters['organization/getOrgWithCoordinates'].length === 1
        ? this.$store.getters['organization/getTree'][0] // root organization only if it is the only org with valid address
        : {}
    },
    getTodayDate() {
      return new Date().toISOString().split('T')[0]
    },
    rules() {
      const definitions = {}
      for (let i = 0; i < this.formData.equipment.length; i++) {
        definitions['serialno' + i] = { required: true }
        definitions['articleno' + i] = { required: true }
        definitions['installed' + i] = { required: true }
        definitions['name' + i] = {}
      }
      return buildValidationRules(this.$i18n.locale, definitions)
    },
    couponCodeMessageClass() {
      if (this.couponCodeMessage === CouponCodeMessage.SUCCESS)
        return 'text-green-700'
      return 'text-red-600'
    },
    couponCodeHtml() {
      if (!this.couponCodeMessage) return
      const markdown = this.$t(this.couponCodeMessage)
      return toHTML(markdown)
    }
  },
  created() {
    this.validateSerialAndRef = debounce(async (index) => {
      if (
        this.formData.equipment[index].serialno &&
        this.formData.equipment[index].articleno
      ) {
        this.$emit('loadingEvent', { isLoading: true })
        try {
          const { data } = await this.$apollo.query({
            query: CHECK_SERIAL_QUERY,
            variables: {
              articleno: this.formData.equipment[index].articleno,
              serialno: this.formData.equipment[index].serialno
            }
          })
          this.showSerialWarning[index] = !data?.getArticleSerialCombination
            ?.result
          this.showSerialWarning = [...this.showSerialWarning] // for reactivity
        } catch (e) {}
        this.$emit('loadingEvent', { isLoading: false })
      }
    }, 500)
  },
  mounted() {
    this.formData = {
      ...this.formData,
      practice: this.organization?.name || null,
      street: this.organization?.street || '',
      zip: this.organization?.zip || '',
      town: this.organization?.city || '',
      country: this.organization?.country
        ? getName(this.organization?.country)
        : ''
    }
  },
  methods: {
    onAddEquipment() {
      this.formData.equipment.push({
        serialno: '',
        articleno: '',
        installed: '',
        name: ''
      })
    },
    onRemoveEquipment() {
      const lastIndex = this.formData.equipment.length - 1
      equipmentFields.forEach(
        (field) => (this.$refs.form.formData[field + lastIndex] = '')
      )
      this.formData.equipment.pop()
      this.showSerialWarning.pop()
    },
    onPlaceChanged(address, place) {
      const {
        street,
        zip,
        city,
        country: countryCode,
        placeId
      } = getLocationInfo(address, place)
      const country = getName(countryCode)

      this.formData = {
        ...this.formData,
        street,
        zip,
        town: city,
        country,
        google_places_id: placeId
      }
    },
    onChange(name, val) {
      if (name in this.formData) {
        this.formData[name] = val
      } else {
        equipmentFields.forEach((field) => {
          if (name.startsWith(field)) {
            const index = name.split(field)[1]
            this.formData.equipment[index][field] = val
          }
        })
      }
      // clear data for address validation
      if (['street', 'zip', 'town'].includes(name)) {
        this.formData.google_places_id = ''
      }
    },
    onSubmit({ validation }) {
      if (!this.formData.coupon_code) this.formData.coupon_code = undefined
      if (!validation.$invalid) this.$emit('submit', this.formData)
    },
    submit() {
      this.$refs.form.handleSubmit()
    },
    validate() {
      this.$refs.form.handleValidate()
    },
    onValidate($event) {
      this.$emit('validate', $event, this.applyCoupon)
    },
    onScan({ referenceNumber, serialNumber }, index) {
      this.$refs.form.formData['serialno' + index] = serialNumber
      this.$refs.form.formData['articleno' + index] = referenceNumber
      this.formData.equipment[index].serialno = serialNumber
      this.formData.equipment[index].articleno = referenceNumber
    },
    async checkCoupon() {
      let isValid = false
      this.$emit('loadingEvent', { isLoading: true })
      try {
        const {
          data: { getCouponValidity = false } = {}
        } = await this.$apollo.query({
          query: CHECK_COUPON_QUERY,
          variables: {
            coupon: this.formData.coupon_code
          }
        })
        isValid = getCouponValidity
      } catch (e) {}
      this.$emit('loadingEvent', { isLoading: false })
      return isValid
    },
    async applyCoupon() {
      if (!this.formData.coupon_code) return true
      this.couponCodeMessage = null
      const isValid = await this.checkCoupon()
      if (isValid) {
        this.couponCodeMessage = CouponCodeMessage.SUCCESS
        this.isCouponApplied = true
      } else {
        this.couponCodeMessage = CouponCodeMessage.ERROR
        this.formData.coupon_code = undefined
      }
      return isValid
    },
    removeCoupon() {
      this.couponCodeMessage = null
      this.isCouponApplied = null
      this.formData.coupon_code = undefined
    }
  }
}
