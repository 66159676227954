//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get, isString, isObject, debounce } from 'lodash-es'
import NotificationMixin from '~/mixins/notification-mixin/NotificationMixin'

export default {
  name: 'MoleculesFormLazyLoadingSelect',
  mixins: [NotificationMixin],
  inheritAttrs: false,
  props: {
    value: {
      type: Object,
      default: () => ({ id: '', name: '' })
    },
    hasError: {
      type: Boolean,
      required: true
    },
    meta: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    },
    onInput: {
      type: Function,
      required: true
    },
    validationContext: {
      type: Object,
      required: true
    },
    cssClasses: {
      type: Array,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    onInputDebounce: { cancel: () => null },
    onBlurDebounce: { cancel: () => null },
    inputModel: '',
    isLoading: false
  }),
  computed: {
    iconName() {
      if (this.isLoading) {
        return 'svg/small-spinner'
      }

      return this.value.name === this.inputModel && this.value.id
        ? 'check'
        : 'search'
    }
  },
  watch: {
    value: {
      immediate: true,
      handler({ name }) {
        this.inputModel = name
      }
    }
  },
  methods: {
    onFocus(openDropdown) {
      this.validationContext.$reset()
      openDropdown()
    },
    onBlur() {
      // Debounce is used to avoid a flickering between
      // the selction of an item and the red error status.
      this.onBlurDebounce = debounce(() => {
        const hasValueChanged = this.inputModel !== this.value.name
        const isValueEmpty = this.inputModel.length === 0

        if (hasValueChanged || isValueEmpty) {
          this.$emit('input', { id: '', name: '' })
        }
      }, 50)
      this.onBlurDebounce()
    },
    async _onInput(input) {
      const value = get(input, ['target', 'value'], '')

      if (value.length === 0) return

      try {
        this.inputModel = value
        this.onInputDebounce.cancel()
        this.updateOnInputDebounce()
        await this.onInputDebounce(value)
      } catch (error) {
        const errorMessage = this.extractErrorMessage(error)
        await this.notifyError(errorMessage)
      }
    },
    onSelect(item, close) {
      this.onBlurDebounce.cancel()
      this.$emit('input', item)
      close()
    },
    updateOnInputDebounce() {
      this.onInputDebounce = debounce(async (value) => {
        await this.onInput(value)
        this.isLoading = false
      }, 300)

      this.isLoading = true
    },
    extractErrorMessage(error) {
      const DEFAULT_ERROR_MESSAGE = 'Unknown Error'

      if (isString(error)) {
        return error
      }

      if (isObject(error)) {
        return get(error, ['message'], DEFAULT_ERROR_MESSAGE)
      }

      return DEFAULT_ERROR_MESSAGE
    }
  }
}
